import React from 'react'
import Styles from './slider.module.css'
import styles2 from './sliderScss.module.scss';
import SlideShow from '../slideShow'
import "./slider.css";

import PLATFORM from '../../../../assets/images/PLATFORM.png'
import Standard from '../../../../assets/images/Standard.png'
import Chamber from '../../../../assets/images/WetChamber.png'
import Chamber2 from '../../../../assets/images/Chamber.png'
import Coating from '../../../../assets/images/Coating.png'
import NewTrox from '../../../../assets/images/NewTrox.png'

import Coating1 from '../../../../assets/images/Coating1.png'
import Curing from '../../../../assets/images/Curing.png'
import Cleaning from '../../../../assets/images/Cleaning.png'
import Load from '../../../../assets/images/Load.png'
import { Button, Col, Row } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

export default function Equipment() {


  return (
    <>
      <div className={styles2.bannerPlatform}>

        <div className={`${Styles.sliderContent} ${styles2.sliderContent2}`}>

          <Row gutter={24} className='d-block' >
            <Col xs={24} sm={12} md={12} >
              <h5 >Process Equipment</h5>
              <h4 >NewTrox 3C Tool</h4>

              <ul>
                <li><p>Elevate your manufacturing process with the NewTrox 3C tool, our
                  comprehensive end-to-end solution designed for various permanent coating
                  applications, including masking and removal.</p>
                </li>
                <li> <p>This advanced system seamlessly integrates with coating, multi-stacking
                  curing, and pre-/post-single wafer cleaning modules to deliver unmatched
                  precision and cleanliness.

                </p>
                </li>

              </ul>
              <div className={styles2.SalesBtn}>
                <button className="ContactBtn" style={{ marginRight: '15px' }}>Get a demo <i class="fas fa-arrow-right" aria-hidden="true" style={{ paddingLeft: '2px' }}></i>
                </button>
                <Link to="/contact"> <button className="SalesBtn SalesBtn2" >Contact sales</button> </Link>
              </div>

            </Col>
            <Col xs={24} sm={12} md={12} >
              <LazyLoadImage src={NewTrox} alt="ce" />
            </Col>
            <Col xs={24} sm={12} md={24} className={styles2.SalesBtn}>
              <h5 >Key features</h5>
            </Col>
            <Col xs={24} sm={12} md={8} className={styles2.grid3}>
              <h5 className='subtit'>Coating</h5>
              <p><b>a.</b> Spin/Spray Coating</p>
              <p><b>b.</b > Low-high Viscosity</p>
              <p><b>c.</b > High Throughput</p>
              <p><b>d.</b > Low Materials Wastage
              </p>
              <p><b>e.</b > Less Maintenance Needed
              </p>
              <LazyLoadImage src={Coating1} alt="ce" />
            </Col>
            <Col xs={24} sm={12} md={8} className={styles2.grid3}>
              <h5 className='subtit'>Curing</h5>
              <p><b>a.</b>Selective & Uniform Curing
              </p>
              <p><b>b.</b > High Throughput</p>
              <p><b>c.</b > Single and Batch Curing</p>
              <p><b>d.</b > Minimum/NO Post Warpage</p>
              <p><b>e.</b > Low-high Pressure Curing
              </p>
              <LazyLoadImage src={Curing} alt="ce" />
            </Col>
            <Col xs={24} sm={12} md={8} className={styles2.grid3} >
              <h5 className='subtit'>Cleaning</h5>
              <p><b>a.</b> High Throughput</p>
              <p><b>b.</b > Single and Batch Cleaning</p>
              <p><b>c.</b > DI or Chemical Cleaning
              </p>
              <p><b>d.</b > Optional Heated Source</p>
              <p><b>e.</b > Front and/or Cleaning</p>
              <LazyLoadImage src={Cleaning} alt="ce" />
            </Col>

          </Row>


        </div>
      </div>
      <div className={styles2.bannerPlatform}>

        <div className={`${Styles.sliderContent} ${styles2.sliderContent2}`}>

          <Row gutter={24} className='d-block' >
            <Col xs={24} sm={12} md={14} >
              <h5 >Process Equipment</h5>
              <h4 >ShieldOx: Cu Passivation Coating Tool</h4>

              <ul>
                <li><p>Our advanced copper passivation coater is cutting-edge, fully automated
                  strip and magazine handling equipment with isolated wet and dry modules
                  configured for high productivity and performance.</p>
                </li>
                <li> <p>It delivers superior corrosion protection for wire-bonded devices through a
                  high-performance corrosion inhibitor.


                </p>
                </li>
                <li> <p>Adhering to SEMI standards with MindOx’s process-automated software and
                  SECS/GEM compliance ensures optimal performance and reliability.</p>
                </li>
                <li> <p>The process extendibility is enabled for hybrid bonding applications</p>
                </li>
              </ul>
              <ul className={styles2.checkClass}>
                <li> Fully Automated</li>
                <li> Higher TPT for Magazine Handling</li>
                <li> SEMI Certification</li>
                <li> SECS/GEM</li>
                <li> Higher MTBC/MTBF</li>
                <li> Lower Maintenance Cost</li>
              </ul>
              <div className={styles2.SalesBtn}>
                <button className="ContactBtn" style={{ marginRight: '15px' }}>Get a demo <i class="fas fa-arrow-right" aria-hidden="true" style={{ paddingLeft: '2px' }}></i>
                </button>
                <Link to="/contact"><button className="SalesBtn SalesBtn2">Contact sales</button></Link>
              </div>

            </Col>
            <Col xs={24} sm={12} md={10} >
              <LazyLoadImage className={styles2.CoatingImg} src={Coating} alt="ce" />
            </Col>
            <Col xs={24} sm={12} md={24} className={styles2.SalesBtn}>
              <h5 >Key features</h5>
            </Col>
            <Col xs={24} sm={12} md={18} className={styles2.grid3}>
              <div className={styles2.Coatingfeatures}>
                <p><b>a.</b> Copper in wire-bond devices corrodes, weakening bonds and causing failure.</p>
                <p><b>b.</b > Inhibitor coating technology protects copper from corrosion, reducing wire lift-off rates.</p>
                <p><b>c.</b > With this coating, wire lift-off rates decrease from 30-40% to under 5%.</p>

              </div>
              <div className={styles2.Coatingfeatures}>
                <p><b>a.</b> Durability in harsh conditions</p>
                <p><b>b.</b > Effectively prevents corrosion, ensuring long-term reliability.</p>
                <p><b>c.</b > Confirms structural integrity and bonding quality.</p>

              </div>

              <div className={styles2.Coatingfeatures}>
                <p><b>a.</b> GUI with IMM camera for process monitoring.</p>
                <p><b>b.</b > ≥ 98.5% yield after 600-unit test</p>
                <p><b>c.</b > Passed High Temperature Storage Life (HTSL) at 175°C for 504 Hours</p>
                <p><b>d.</b > Passed Temperature Cycling Test (TC3000) to AEC Grade 1 Standards (-55°C to 150°C)</p>
                <p><b>e.</b > Withstood Temperature-Humidity-Bias Test (THB) at 85°C for 2</p>

              </div>




            </Col>
            <Col xs={24} sm={12} md={6} className={styles2.grid4}>

              <LazyLoadImage src={Chamber} alt="ce" />
              <p><b>Dry Chamber</b></p>
              <LazyLoadImage src={Chamber2} alt="ce" />
              <p><b>Wet Chamber</b></p>
            </Col>


          </Row>


        </div>
      </div>
    </>
  )
}
