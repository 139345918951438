import React, { Suspense, useEffect } from "react";
import './App.css';
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";

import Navbar from "./components/navbar/Navbar";
import ReactGA from "react-ga";
import Footer from "./components/footer/footer";
import ScrollToTop from "./components/scrolltoTop";
import Page404 from "./components/404Page";
import Platform from "./components/pages/home/slider/platform";
import Equipment from "./components/pages/home/slider/equipment";
import Automation from "./components/pages/home/slider/automation";

const Tracking_ID = "G-WPD3L9D3E5";
ReactGA.initialize(Tracking_ID);

const Home = React.lazy(() => import('./components/pages/home'));
const Services = React.lazy(() => import('./components/pages/services'));
const ExtendedServices = React.lazy(() => import('./components/pages/services/extendedServices'));
const AdvanceAnalytics = React.lazy(() => import('./components/pages/services/advanceAnalytics'));
const Development = React.lazy(() => import('./components/pages/developement/'));
const ProductDevelopment = React.lazy(() => import('./components/pages/developement/newDev'));
const KnowledgeHub = React.lazy(() => import('./components/pages/developement/knowledge'));
const Company = React.lazy(() => import('./components/pages/company'));
const AboutUs = React.lazy(() => import('./components/pages/company/about'));
const Careers = React.lazy(() => import('./components/pages/company/career'));
const Internships = React.lazy(()=> import ('./components/pages/company/career/internships/internship'));
const Contact = React.lazy(() => import('./components/pages/contact'));

const JobDetails = React.lazy(() => import('./components/pages/company/career/jobDetails/jobDetail'))

const Product = React.lazy(() => import('./components/products-pages'));
const EFEM = React.lazy(() => import('./components/products-pages/efem'));
const Accessories = React.lazy(() => import('./components/products-pages/accessories'));


const Coater = React.lazy(() => import('./components/products-pages/coater'));
// const Metrology = React.lazy(() => import('./components/products-pages/metrology'));


const LOVAF300 = React.lazy(() => import('./components/products-pages/loadportProducts/LOVAF300'));
const LOVAC380 = React.lazy(() => import('./components/products-pages/loadportProducts/LOVAC380'));
const LOVAC380OC = React.lazy(() => import('./components/products-pages/loadportProducts/LOVAC380OC'));
const LOVAFOCA150 = React.lazy(() => import('./components/products-pages/loadportProducts/LOVAFOCA150'));
const LOVAFOCA200 = React.lazy(() => import('./components/products-pages/loadportProducts/LOVAFOCA200'));
const LOVAMOCLP150 = React.lazy(() => import('./components/products-pages/loadportProducts/LOVAMOCLP150'));
const LOVAP200 = React.lazy(() => import('./components/products-pages/loadportProducts/LOVAP200'));
const LOVAMOCLP200 = React.lazy(() => import('./components/products-pages/loadportProducts/LOVAMOCLP200'));






function App() {

  useEffect(() => {
    // Clear all cookies
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });

    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Suspense fallback={<div></div>}>
          <Routes>

            <Route path="/" element={<Home />} />
            <Route path="/platform" element={<Platform />} />
            <Route path="/equipment" element={<Equipment />} />
            <Route path="/automation" element={<Automation />} />
            <Route path="/product" element={<Product />}>
              <Route path="/product" element={<Navigate replace to="efem" />} />
              <Route path="efem" element={<EFEM />} />
              <Route path="accessories" element={<Accessories />} />
            </Route>

            <Route path="/product/accessories/loadport/lova-f-300" element={<LOVAF300 />} />
            <Route path="/product/accessories/loadport/lova-c-380" element={<LOVAC380 />} />
            <Route path="/product/accessories/loadport/lova-c-380-oc" element={<LOVAC380OC />} />
            <Route path="/product/accessories/loadport/lova-f-oca-150" element={<LOVAFOCA150 />} />
            <Route path="/product/accessories/loadport/lova-f-oca-200" element={<LOVAFOCA200 />} />
            <Route path="/product/accessories/loadport/lova-m-oclp-150" element={<LOVAMOCLP150 />} />
            <Route path="/product/accessories/loadport/lova-p-200" element={<LOVAP200 />} />
            <Route path="/product/accessories/loadport/lova-m-oclp-200" element={<LOVAMOCLP200 />} />


            {/* <Route  path="/metrology.html" element={<Metrology/>}/> */}
            <Route path="/spin-spray-coater.html" element={<Coater />} />

            <Route path="/services" element={<Services />}>
              <Route path="/services" element={<Navigate replace to="extended-services.html" />} />
              <Route path="extended-services.html" element={<ExtendedServices />} />
              <Route path="advanced-analytics.html" element={<AdvanceAnalytics />} />
            </Route>
            <Route path="/development" element={<Development />}>
              <Route path="/development" element={<Navigate replace to="new-product-development.html" />} />
              <Route path="new-product-development.html" element={<ProductDevelopment />} />
              <Route path="knowledge-Hub.html" element={<KnowledgeHub />} />
            </Route>
            <Route path="/company" element={<Company />}>
              <Route path="/company" element={<Navigate replace to="about-us.html" />} />
              <Route path="about-us.html" element={<AboutUs />} />
              <Route path="careers" element={<Careers />} />
              <Route path="careers/vacancy/:id" element={<JobDetails />} />
              <Route path= "careers/internship" element={<Internships />}/>
            </Route>
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<Page404 />} />

          </Routes>
        </Suspense>
        <a aria-label="WhatsApp" className="whatsapp" href="https://web.whatsapp.com/send?phone=+6581128732&text&app_absent=0" target="blank"  >
          <i className="fa fa-whatsapp my-float"></i>
        </a>
        <Footer />
      </Router>
    </>
  );
}

export default App;
