import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import footerlogo from '../../assets/images/logo/MindoxTechno.png';
import { FaFacebookF,FaTwitter,FaInstagram ,FaLinkedinIn} from "react-icons/fa";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
function Footer() {


  return (
   <footer>
        <div className="footer-top">
            {/* <div className="footer-logo">  <LazyLoadImage  src={footerlogo} alt="MindoxTechno Logo" width="200px" height="160px"/></div> */}
            <div className="footer-container">
                <div className="footer-link f1">
                    <h4>Products</h4>
                    <h5><Link to="/platform">Platform Solution</Link></h5>
                    <h5><Link to="/equipment">Process Equipment</Link></h5>
                    <h5><Link to="/automation"> Automation Solution</Link></h5>
                </div>
                <div className="footer-link f1">
                    <h4>Services</h4>
                    <a href="https://www.mindoxtechno.com/services/extended-services.html"><h5>Engineering Resource</h5></a>
                </div>
                <div className="footer-link f1">
                    <h4>Technologies</h4>
                    <a href="https://www.mindoxtechno.com/development/new-product-development.html"><h5>Development</h5></a>
                   
                         
                </div>
                <div className="footer-link f1">
                    <h4>Life at MindOx</h4>
                    <h5><Link to="/company/about-us.html">About</Link></h5>
                    <h5><Link to="/company/careers">Careers</Link></h5>
                    <h5><Link to="/services/extended-services.html">Leadership</Link></h5>
                    <h5><Link to="/development/knowledge-Hub.html">Knowledge Hub</Link></h5>
                    <h5><Link to="/contact">Contact</Link></h5>
                           
                </div>
                <div className="footer-link f1">
                    <h4> Want to know more or do you have a question ?</h4>
                    <h5><Link to="/contact"><u>Book a demo</u></Link></h5>
                    <h5>Or you can reach out to us on workdays from 9am till 5pm.</h5>
                    <h5>Singapore</h5>
                    <h5><a href="tel:+6569838172">+65 6983 8172</a></h5>
                    {/* <div className="footer-icon">
                        <div className="footer-icon-box"> <a aria-label="Facebook" href="//www.facebook.com/mindoxtechno/"><FaFacebookF  /></a>  </div>
                        <div className="footer-icon-box"> <a aria-label="Twitter" href="//www.twitter.com/mindoxtechno/"><FaTwitter  /></a>  </div>
                        <div className="footer-icon-box"> <a  aria-label="Instagram" href="//www.Instagram.com/mindoxtechno/"><FaInstagram  /></a>  </div>
                        <div className="footer-icon-box"><a  aria-label="LinkedIn" href="//www.linkedin.com/company/mindox-techno/"><FaLinkedinIn  /></a> </div>
                     </div> */}
                </div>
            </div>
        </div>
        <div className="footer-bottom">
             <p> Copyright © {new Date().getFullYear()}{" "}<span> Mindox Techno</span>, All rights reserved.</p>
        </div>
   </footer>
  );
}

export default Footer;
