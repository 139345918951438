import React from "react";
import { Link } from "react-router-dom";

function Page404() {
    
 
    
  return (
    <div class="page404">

          <h1>404</h1>
          <h2>Sorry, the page not found</h2>
          <Link to="/" ><button class="btn404">Home</button></Link>
    </div>
  );
}

export default Page404;
